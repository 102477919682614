import {INDIA_ISO_SUFFIX, LOCALE_SHORTHANDS} from '../constants';

import {format, formatDistance, formatISO, subDays} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import i18n from 'i18next';

let locale = null;

const getLocale = () => {
  import('date-fns/locale/').then((localePackage) => {
    locale =
      localePackage[
        LOCALE_SHORTHANDS[i18n.language || window.localStorage.i18nextLng]
      ];
  });
};

export const isDevelopmentOrTest = () => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
    return true;
  return false;
};

export const getIndiaDate = () => {
  return utcToZonedTime(new Date(), 'Asia/Kolkata');
};

export const getIndiaDateISO = () => {
  return formatISO(getIndiaDate(), {representation: 'date'});
};

export const getIndiaYesterdayISO = () => {
  return formatISO(subDays(getIndiaDate(), 1), {representation: 'date'});
};

export const getPreviousDate = (dateString) => {
  const year = parseInt(dateString.slice(0, 4));
  const month = parseInt(dateString.slice(5, 7));
  const day = parseInt(dateString.slice(8, 10));

  const d = new Date();
  d.setFullYear(year, month, day);
  d.setDate(d.getDate() - 1);
  d.setMonth(d.getMonth() - 1);

  const previousDateString = d.toISOString().split('T')[0];
  return previousDateString;
};

export const formatLastUpdated = (unformattedDate) => {
  getLocale();
  return formatDistance(new Date(unformattedDate), new Date(), {
    locale: locale,
  });
};

export const parseIndiaDate = (isoDate) => {
  return utcToZonedTime(new Date(isoDate + INDIA_ISO_SUFFIX), 'Asia/Kolkata');
};

export const formatDate = (unformattedDate, formatString) => {
  if (
    typeof unformattedDate === 'string' &&
    unformattedDate.match(/^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/g)
  )
    unformattedDate += INDIA_ISO_SUFFIX;
  const date = utcToZonedTime(new Date(unformattedDate), 'Asia/Kolkata');
  return format(date, formatString, {
    locale: locale,
  });
};

export function sliceTimeseriesFromEnd(timeseries, days) {
  return timeseries.slice(-days);
}

export const abbreviateNumber = (number) => {
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(1) + 'K';
  if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(1) + 'M';
  if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(1) + 'B';
  if (number >= 1e12) return +(number / 1e12).toFixed(1) + 'T';
};

export const formatNumber = (value, mode = 'long') => {
  if (isNaN(value)) return '-';

  const numberFormatter = new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: 2,
  });

  if (mode === 'short') {
    return abbreviateNumber(value);
  }

  return numberFormatter.format(value);
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeAll = (s) => {
  if (typeof s !== 'string') return '';
  const str = s.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = capitalize(str[i]);
  }
  return str.join(' ');
};

export const abbreviate = (s) => {
  return s.slice(0, 1) + s.slice(1).replace(/[aeiou]/gi, '');
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getStatistic = (data, type, statistic, perMillion = false) => {
  if (type !== 'delta' && type !== 'total') return false;
  let count;
  if (statistic === 'active') {
    const confirmed = data?.[type]?.confirmed || 0;
    const deceased = data?.[type]?.deceased || 0;
    const recovered = data?.[type]?.recovered || 0;
    const migrated = data?.[type]?.migrated || 0;
    count = confirmed - deceased - recovered - migrated;
  } else {
    count = data?.[type]?.[statistic] || 0;
  }
  return perMillion ? (1e6 * count) / data?.meta?.population || 0 : count;
};

export const getLevitt = (todayData, yesterdayData, statistic) => {
  const type = 'total';
  const epsilon = 0.000000001;

  let todayCount;
  let yesterdayCount;
  if (statistic === 'active') {
    const todayConfirmed = todayData?.[type]?.confirmed || 0;
    const todayDeceased = todayData?.[type]?.deceased || 0;
    const todayRecovered = todayData?.[type]?.recovered || 0;
    const todayMigrated = todayData?.[type]?.migrated || 0;
    todayCount =
      todayConfirmed - todayDeceased - todayRecovered - todayMigrated;

    const yesterdayConfirmed = yesterdayData?.[type]?.confirmed || 0;
    const yesterdayDeceased = yesterdayData?.[type]?.deceased || 0;
    const yesterdayRecovered = yesterdayData?.[type]?.recovered || 0;
    const yesterdayMigrated = yesterdayData?.[type]?.migrated || 0;
    yesterdayCount =
      yesterdayConfirmed -
      yesterdayDeceased -
      yesterdayRecovered -
      yesterdayMigrated;
  } else {
    todayCount = todayData?.[type]?.[statistic] || 0;
    yesterdayCount = yesterdayData?.[type]?.[statistic] || 0;
  }

  const levitt = (todayCount + epsilon) / (yesterdayCount + epsilon);
  return levitt;
};

export const fetcher = (url) => {
  return fetch(url).then((response) => {
    return response.json();
  });
};
